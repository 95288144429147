import { memo, useEffect, useState } from "react";
import { ROLE_TYPE } from "../../constants/constants";
import { BodyText, SmallHeightDivider, StyledButtonOutlined } from "../../theme/Styles";
import { replaceGuionToSlashFromString } from "../../utilities/functions/StringUtil";
import { statusColors, variantStatus } from "./RequestCardConstants";
import {
    ActionRequiredTitle,
    ButtonContainer,
    ColumnContainerRequest,
    Container,
    ActionRequiredContainer,
    IconContainer,
    ProgressBarContainer,
    ProgressBarPercent,
    ProgressBarTitle,
    RowContainer,
    StyledWarningIcon,
    Title,
    ActionRequiredWarning,
} from "./styles/RequestCardStyles";


function RequestCard({
    statusID,
    rolBase,
    title,
    citizenID,
    projectName,
    date,
    requestCode,
    status,
    company_user_rol,
    onClick,
    onClaimModalVisibility,
    percent,
    actionRequired,
    solution,
    solutionColor,
    docIdentification,
    companyName,
    companyID,
    handleCompanySelected,
    code,
    setRequestCode,
    userName,
}) {
    const [localVariant, setLocalVariant] = useState(variantStatus.find((variant) => status === variant.id));

    useEffect(() => {
        if (localVariant === undefined) {
            setLocalVariant(variantStatus[2]);
        }
    }, [localVariant]);

  

    return actionRequired !== null ? (
        <ActionRequiredContainer>
           

            <ActionRequiredWarning>
                    <IconContainer>
                        <StyledWarningIcon />
                    </IconContainer>
                <ActionRequiredTitle>Esta solicitud requiere de tu acción para continuar.</ActionRequiredTitle>
            </ActionRequiredWarning>
            <SmallHeightDivider />
            <SmallHeightDivider />
           
            {/* <RowContainer>
                <div>
                    <IconContainer>
                        <StyledWarningIcon />
                    </IconContainer>
                    <ActionRequiredTitle>Esta solicitud requiere de tu acción para continuar.</ActionRequiredTitle>
                </div>
            </RowContainer> */}

            {solution !== null && (
                <RowContainer>
                    <IconContainer>
                        <StyledWarningIcon customColor={statusColors[solutionColor]} />
                    </IconContainer>
                    <ActionRequiredTitle>{solution}.</ActionRequiredTitle>
                </RowContainer>
            )}

            <RowContainer style={{ justifyContent: "space-between" }}>
                <Title>{title}</Title>
            </RowContainer>

            <RowContainer>
                <ColumnContainerRequest>
                    {projectName !== null && (
                        <BodyText>
                            <strong>Nombre del Proyecto</strong> : {projectName}
                        </BodyText>
                    )}
                    <BodyText>
                        <strong>Solicitud No</strong>: {requestCode}
                    </BodyText>
                    {companyName && (
                        <BodyText>
                            <strong>Nombre Compañia </strong>: {companyName}
                        </BodyText>
                    )}
                    <BodyText>
                        <strong>Solicitado por</strong>: {userName}
                    </BodyText>
                    <BodyText>
                        <strong>Estado</strong> : {status}
                    </BodyText>
                    <BodyText>
                        <strong>Fecha </strong>: {new Date(replaceGuionToSlashFromString(date)).toLocaleDateString()}
                    </BodyText>
                </ColumnContainerRequest>

                <ButtonContainer>
                    <StyledButtonOutlined onClick={onClick} variant="contained">
                        {statusID == 10 || statusID == 8 || statusID == 7
                            ? "VER DETALLE"
                            : rolBase === ROLE_TYPE.COMPANY
                            ? "VER DETALLE"
                            : docIdentification !== citizenID
                            ? "VER DETALLE"
                            : actionRequired !== undefined
                            ? "RESPONDER"
                            : "VER DETALLE"}
                    </StyledButtonOutlined>
                    <SmallHeightDivider />
                    {(rolBase === ROLE_TYPE.COMPANY || Number(company_user_rol) === ROLE_TYPE.REPRESENTATIVE) &&
                    statusID != 10 &&
                    statusID != 8 &&
                    statusID != 7 ? (
                        <StyledButtonOutlined
                            style={{ backgroundColor: !docIdentification ? "#FF4444" : "", color: !docIdentification ? "white" : "" }}
                            onClick={() => {
                                setRequestCode(code);
                                handleCompanySelected(companyID);
                                onClaimModalVisibility();
                            }}
                            variant="contained"
                        >
                            {!docIdentification ? "ASIGNAR SOLICITANTE" : "CAMBIAR SOLICITANTE"}
                        </StyledButtonOutlined>
                    ) : null}
                    <SmallHeightDivider />
                </ButtonContainer>
            </RowContainer>

            <SmallHeightDivider />
            <RowContainer>
                <ProgressBarContainer>
                    <ProgressBarTitle variant={localVariant?.variant}>
                        {
                            //STATUSID 8 IS REJECTED and 10 is canceled
                            statusID === 8 ? "RECHAZADO" : statusID === 10 ? "CANCELADO" : percent + " COMPLETADO"
                        }
                    </ProgressBarTitle>
                    <ProgressBarPercent variant={localVariant?.variant} percent={statusID === 8 || statusID === 10 ? "100" : percent} />
                </ProgressBarContainer>
            </RowContainer>
            <SmallHeightDivider />
        </ActionRequiredContainer>
    ) : (
        <Container>
            <SmallHeightDivider />
            <SmallHeightDivider />
            {solution !== null && (
                <RowContainer>
                    <IconContainer>
                        <StyledWarningIcon customColor={statusColors[solutionColor]} />
                    </IconContainer>
                    <ActionRequiredTitle>{solution}.</ActionRequiredTitle>
                </RowContainer>
            )}

            <RowContainer style={{ justifyContent: "space-between" }}>
                <Title>{title}</Title>
            </RowContainer>

            <RowContainer>
                <ColumnContainerRequest>
                    {projectName !== null && (
                        <BodyText>
                            <strong>Nombre del Proyecto</strong> : {projectName}
                        </BodyText>
                    )}
                    <BodyText>
                        <strong>Solicitud No</strong>: {requestCode}
                    </BodyText>
                    {companyName && (
                        <BodyText>
                            <strong>Nombre Compañia </strong>: {companyName}
                        </BodyText>
                    )}
                    <BodyText>
                        <strong>Solicitado por</strong>: {userName}
                    </BodyText>
                    <BodyText>
                        <strong>Estado</strong> : {status}
                    </BodyText>
                    <BodyText>
                        <strong>Fecha </strong>: {new Date(replaceGuionToSlashFromString(date)).toLocaleDateString()}
                    </BodyText>
                </ColumnContainerRequest>

                <ButtonContainer>
                    <StyledButtonOutlined onClick={onClick} variant="contained">
                        {statusID == 10 || statusID == 8 || statusID == 7
                            ? "VER DETALLE"
                            : rolBase === ROLE_TYPE.COMPANY
                            ? "VER DETALLE"
                            : docIdentification !== citizenID
                            ? "VER DETALLE"
                            : actionRequired !== undefined
                            ? "DAR SEGUIMIENTO"
                            : "VER DETALLE"}
                    </StyledButtonOutlined>
                    <SmallHeightDivider />
                    {(rolBase === ROLE_TYPE.COMPANY || Number(company_user_rol) === ROLE_TYPE.REPRESENTATIVE) &&
                    statusID != 10 &&
                    statusID != 8 &&
                    statusID != 7 ? (
                        <StyledButtonOutlined
                            style={{ backgroundColor: !docIdentification ? "#FF4444" : "", color: !docIdentification ? "white" : "" }}
                            onClick={() => {
                                setRequestCode(code);
                                handleCompanySelected(companyID);
                                onClaimModalVisibility();
                            }}
                            variant="contained"
                        >
                            {!docIdentification ? "ASIGNAR SOLICITANTE" : "CAMBIAR SOLICITANTE"}
                        </StyledButtonOutlined>
                    ) : null}
                    <SmallHeightDivider />
                </ButtonContainer>
            </RowContainer>

            <SmallHeightDivider />
            <RowContainer>
                <ProgressBarContainer>
                    <ProgressBarTitle variant={localVariant?.variant}>
                        {
                            //STATUSID 8 IS REJECTED and 10 is canceled
                            statusID === 8 ? "RECHAZADO" : statusID === 10 ? "CANCELADO" : percent + " COMPLETADO"
                        }
                    </ProgressBarTitle>
                    <ProgressBarPercent variant={localVariant?.variant} percent={statusID === 8 || statusID === 10 ? "100" : percent} />
                </ProgressBarContainer>
            </RowContainer>
            <SmallHeightDivider />
        </Container>
    );
}

export default memo(RequestCard);
