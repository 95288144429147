import { styled } from '@mui/system';
import COLORS from '../../../../theme/Colors';
import FONTS_FAMILY from '../../../../theme/FontsFamily';


export const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width:320px)': {
        width: '320px',
    },
    '@media (min-width:768px)': {
        width: "768px",
    },
    '@media (min-width:1200px)': {
        width: "1000px",
    },
});


export const ButtonsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
});

export const ButtonContainer = styled('div')({
    width: '30%',
});

export const DisclaimerButtonCotainer = styled('div')({
    display: 'flex',
    justifyContent: 'center'
})


export const FinalStepContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignSelf: 'center',
    alignItems: 'center'
});

export const DisclaimerSubTitle = styled("p")({
    color: COLORS.secondary,
    textAlign: "center",
    marginBottom: "10px",
    alignSelf: 'center',
    fontFamily: FONTS_FAMILY.regular.body,
    "@media (min-width:320px)": {
      fontSize: "15px",
      width: '350px',
    },
    "@media (min-width:768px)": {
      fontSize: "15px",
      width: '500px',
    },
    "@media (min-width:1200px)": {
      fontSize: "16px",
      width: '700px',
    },
  });
  
  export const DisclaimerTitle = styled("h3")({
    color: COLORS.secondary,
    margin: "0",
    textAlign: "center",
    fontSize: "30px",
    fontFamily: FONTS_FAMILY.regular.title,
    "@media (max-width:768px)": {
      fontSize: "23px",
    },
  });